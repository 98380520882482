#content .side-menu {
    top: 10px;
    padding-right: 10px;
    margin-bottom: 40px;
}

#content .side-menu div:first-child {
    border-left: 10px solid #ffab0d;
    padding: 0px 10px;
    margin: 5px 5px 10px 5px;
    font-weight: bold;
    font-size: 20px;
    color: #004735;
}

button > .page-loader  {
    font-size: inherit;
    color: white;
}

#content .side-menu ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

#content .side-menu ul > li{
    overflow:hidden;
    height: auto;
    max-height: 80px;
    transition: 0.2s max-height ease-out, 0.2s font-size ease-out;
}

#content .side-menu ul ul >li >a::before{
    margin-left: 10px;
}
#content .side-menu ul ul ul >li >a::before{
    margin-left: 20px;
}
#content .side-menu ul.hidden >li{
    max-height: 0px;
    border: 0;
    font-size: 0;
    transition: 0.2s max-height ease-in, 0.2s font-size ease-in;
}

#content .side-menu > ul > li:first-child {
    border-top: 1px solid #dfdfdf;
}

#content .side-menu li {
    border-bottom: 1px solid #dfdfdf;
    margin: 0;
}

#content .side-menu li a {
    display: block;
    padding: 10px;
    text-decoration: none;
    color: black;
    position: relative;
    padding-left: 35px;
    word-break: break-word;
}

#content .side-menu li a::before {
    color: #ffab0d;
    content: '';
    display: inline-block;
    margin-right: 10px;
    width: 15px;
    text-align: center;
    position: absolute;
    left: 10px;
}

#content .side-menu ul ul li a {
    padding-left: 45px;
}

#content .side-menu li.with-submenu a::before {
    content: '❯';
    transform: rotate(0deg);
    transition: all 0.2s linear;
}

#content .side-menu li.with-submenu.show-submenu a::before{
    transform: rotate(90deg);
}

#content .side-menu ul ul li:not(.active):hover{
    filter: none;
}

#content .side-menu li a:hover {
    background: #f7f7f6;
}

#content .side-menu li.active a {
    background: rgb(223, 238, 233);
}

.actual-selector{
    margin: 0;
    padding: 0;
    list-style: none;
}

.actual-selector > li {
    display: inline-block;
    text-decoration: none;
    padding: 10px 0px;
    line-height: 32px;
}

.actual-selector > li > span, .actual-selector > li > span > *{
    vertical-align: middle;
}

.actual-selector > li div.page-loader{
    display: inline-block;
}

.actual-selector > li:not(:first-child) > span::before,
.actual-selector > li:not(:first-child) > input::before{
    color: #ffab0d;
    content: '❯';
    display: inline-block;
    margin-right: 20px;
    margin-left: 20px;
}

.actual-selector.student > li:not(:first-child) > span::before, .actual-selector.student > li:not(:first-child) > input::before,
.actual-selector.search-parameters > li:not(:first-child) > span::before, .actual-selector.search-parameters > li:not(:first-child) > input::before{
    content: '-';
    font-weight: bold;
    color: black;
    margin: 0px 5px;
}
.actual-selector.student > li:nth-child(2) > span::before{
    content: '';
    margin: 0;
    margin-right: 5px;
}

.menu-logo {
    background-image: url(../images/dashboard/de-etlap.jpg);
}

.iskola-kezelese-logo {
    background-image: url(../images/dashboard/iskola-kezelese.jpg);
}

.iskola-kezelese-uj-logo {
    background-image: url(../images/dashboard/iskola-kezelese-uj.jpg);
}

.tamogatas-kezelese-logo {
    background-image: url(../images/dashboard/tamogatas-kezelese.jpg);
}

.tanevek-kezelese-logo {
    background-image: url(../images/dashboard/tanevek-kezelese.jpg);
}

.etkezesi-tipus-kezelese-logo {
    background-image: url(../images/dashboard/etkezesi-tipus-kezelese.jpg);
}
.tanev-aktivalasa-logo {
    background-image: url(../images/dashboard/tanev-aktivalasa.jpg);
}
.tanulo-kezelese-logo {
    background-image: url(../images/dashboard/tanulo-kezelese.jpg);
}

.riportok-logo {
    background-image: url(../images/dashboard/riportok.jpg);
}

.szamlazas-logo,
.szamlak-logo{
    background-image: url(../images/dashboard/szamlazas.jpg);
}

.befizetesek-feltoltese-logo {
    background-image: url(../images/dashboard/befizetesek-feltoltese.jpg);
}

.menzanaptar-logo {
    background-image: url(../images/dashboard/menzanaptar.jpg);
}

.tanuloi-adatok-logo {
    background-image: url(../images/dashboard/tanuloi-adatok.jpg);
}

.tanulo-egyenlege-logo {
    background-image: url(../images/dashboard/tanulo-egyenlege.jpg);
}

.tanuloi-igenylesek-logo {
    background-image: url(../images/dashboard/tanulo-etkezesei.jpg);
}

.jelszo-modositasa-logo {
    background-image: url(../images/dashboard/jelszo-modositasa.jpg);
}

.uj-gyerek-hozzarendelese-logo {
    background-image: url(../images/dashboard/uj-gyerek-hozzarendelese.jpg);
}

.dokumentumok-logo {
    background-image: url(../images/dashboard/dokumentumok.jpg);
}

.igenylesmezok-kezelese-logo {
    background-image: url(../images/dashboard/igenylesmezok-kezelese.jpg);
}

.uzenetek-logo {
    background-image: url(../images/dashboard/uzenetek.jpg);
}

.nemregiben-aktiv-felhasznalok-logo {
    background-image: url(../images/dashboard/nemregiben-aktiv-felhasznalok.jpg);
}

.welcome-description span.help-icon {
    display: inline-block;
    background-image: url(../images/question_mark2.svg);
    background-color: transparent;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: transparent;
    width: 20px;
    height: 20px;
}

button.download_epdf,
a.download_pdf{
    box-sizing: border-box;
    display: inline-block;
    width: 24px;
    border: none;
    outline: none;
    background-size: 24px 24px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
}
a.download_pdf{
    height: 24px;
    background-image: url("../images/download_pdf_normal.svg");
}

button.download_epdf{
    background-image: url("../images/download_pdf.svg");
}

.help-description span.download-icon {
    display: inline-block;
    background-image: url(~@sapkk/app/static/images/download.svg);
    background-color: transparent;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: transparent;
    width: 20px;
    height: 20px;
}
.help-description span.download-epdf-icon {
     display: inline-block;
     background-image: url("../images/download_pdf.svg");
     background-color: transparent;
     background-size: 100% 100%;
     background-repeat: no-repeat;
     color: transparent;
     width: 20px;
     height: 20px;
 }

.help-description span.plus-icon {
    display: inline-block;
    background-image: url(~@sapkk/app/static/images/plus.svg);
    background-color: transparent;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: transparent;
    width: 20px;
    height: 20px;
}

.help-description span.minus-icon {
    display: inline-block;
    background-image: url(~@sapkk/app/static/images/minus.svg);
    background-color: transparent;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: transparent;
    width: 20px;
    height: 20px;
}

.countdown{
    font-size: 50px;
    display: flex;
    justify-content: right;
}

.countdown .digit{
    width: 25px;
}

.countdown div{
    width: 12px;
    position: relative;
    user-select: none;
}

.countdown .digit div{
    top: 0;
    opacity: 1;
    transition: top ease-in-out 0.75s, opacity ease-in-out 0.75s;
}

.countdown .digit[data-value="0"] div:nth-child(3),
.countdown .digit[data-value="0"] div:nth-child(4),
.countdown .digit[data-value="0"] div:nth-child(5),
.countdown .digit[data-value="0"] div:nth-child(6),
.countdown .digit[data-value="0"] div:nth-child(7),
.countdown .digit[data-value="0"] div:nth-child(8),
.countdown .digit[data-value="0"] div:nth-child(9),
.countdown .digit[data-value="0"] div:nth-child(10){
    top: 50px;
    opacity: 0;
}

.countdown .digit[data-value="0"] div:nth-child(2){
    top: -50px;
    opacity: 0;
}

.countdown .digit[data-value="1"] div:nth-child(1){
    top: 50px;
    opacity: 0;
}

.countdown .digit[data-value="1"] div:nth-child(3),
.countdown .digit[data-value="1"] div:nth-child(4),
.countdown .digit[data-value="1"] div:nth-child(5),
.countdown .digit[data-value="1"] div:nth-child(6),
.countdown .digit[data-value="1"] div:nth-child(7),
.countdown .digit[data-value="1"] div:nth-child(8),
.countdown .digit[data-value="1"] div:nth-child(9),
.countdown .digit[data-value="1"] div:nth-child(10) {
    top: -50px;
    opacity: 0;
}


.countdown .digit[data-value="2"] div:nth-child(2){
    top: 50px;
    opacity: 0;
}

.countdown .digit[data-value="2"] div:nth-child(1),
.countdown .digit[data-value="2"] div:nth-child(4),
.countdown .digit[data-value="2"] div:nth-child(5),
.countdown .digit[data-value="2"] div:nth-child(6),
.countdown .digit[data-value="2"] div:nth-child(7),
.countdown .digit[data-value="2"] div:nth-child(8),
.countdown .digit[data-value="2"] div:nth-child(9),
.countdown .digit[data-value="2"] div:nth-child(10) {
    top: -50px;
    opacity: 0;
}


.countdown .digit[data-value="3"] div:nth-child(2),
.countdown .digit[data-value="3"] div:nth-child(3){
    top: 50px;
    opacity: 0;
}

.countdown .digit[data-value="3"] div:nth-child(1),
.countdown .digit[data-value="3"] div:nth-child(5),
.countdown .digit[data-value="3"] div:nth-child(6),
.countdown .digit[data-value="3"] div:nth-child(7),
.countdown .digit[data-value="3"] div:nth-child(8),
.countdown .digit[data-value="3"] div:nth-child(9),
.countdown .digit[data-value="3"] div:nth-child(10) {
    top: -50px;
    opacity: 0;
}


.countdown .digit[data-value="4"] div:nth-child(2),
.countdown .digit[data-value="4"] div:nth-child(3),
.countdown .digit[data-value="4"] div:nth-child(4){
    top: 50px;
    opacity: 0;
}

.countdown .digit[data-value="4"] div:nth-child(1),
.countdown .digit[data-value="4"] div:nth-child(6),
.countdown .digit[data-value="4"] div:nth-child(7),
.countdown .digit[data-value="4"] div:nth-child(8),
.countdown .digit[data-value="4"] div:nth-child(9),
.countdown .digit[data-value="4"] div:nth-child(10) {
    top: -50px;
    opacity: 0;
}

.countdown .digit[data-value="5"] div:nth-child(2),
.countdown .digit[data-value="5"] div:nth-child(3),
.countdown .digit[data-value="5"] div:nth-child(4),
.countdown .digit[data-value="5"] div:nth-child(5){
    top: 50px;
    opacity: 0;
}

.countdown .digit[data-value="5"] div:nth-child(1),
.countdown .digit[data-value="5"] div:nth-child(7),
.countdown .digit[data-value="5"] div:nth-child(8),
.countdown .digit[data-value="5"] div:nth-child(9),
.countdown .digit[data-value="5"] div:nth-child(10) {
    top: -50px;
    opacity: 0;
}

.countdown .digit[data-value="6"] div:nth-child(2),
.countdown .digit[data-value="6"] div:nth-child(3),
.countdown .digit[data-value="6"] div:nth-child(4),
.countdown .digit[data-value="6"] div:nth-child(5),
.countdown .digit[data-value="6"] div:nth-child(6) {
    top: 50px;
    opacity: 0;
}

.countdown .digit[data-value="6"] div:nth-child(1),
.countdown .digit[data-value="6"] div:nth-child(8),
.countdown .digit[data-value="6"] div:nth-child(9),
.countdown .digit[data-value="6"] div:nth-child(10) {
    top: -50px;
    opacity: 0;
}

.countdown .digit[data-value="7"] div:nth-child(2),
.countdown .digit[data-value="7"] div:nth-child(3),
.countdown .digit[data-value="7"] div:nth-child(4),
.countdown .digit[data-value="7"] div:nth-child(5),
.countdown .digit[data-value="7"] div:nth-child(6),
.countdown .digit[data-value="7"] div:nth-child(7){
    top: 50px;
    opacity: 0;
}

.countdown .digit[data-value="7"] div:nth-child(1),
.countdown .digit[data-value="7"] div:nth-child(9),
.countdown .digit[data-value="7"] div:nth-child(10) {
    top: -50px;
    opacity: 0;
}

.countdown .digit[data-value="8"] div:nth-child(2),
.countdown .digit[data-value="8"] div:nth-child(3),
.countdown .digit[data-value="8"] div:nth-child(4),
.countdown .digit[data-value="8"] div:nth-child(5),
.countdown .digit[data-value="8"] div:nth-child(6),
.countdown .digit[data-value="8"] div:nth-child(7),
.countdown .digit[data-value="8"] div:nth-child(8){
    top: 50px;
    opacity: 0;
}

.countdown .digit[data-value="8"] div:nth-child(1),
.countdown .digit[data-value="8"] div:nth-child(10) {
    top: -50px;
    opacity: 0;
}

.countdown .digit[data-value="9"] div:nth-child(2),
.countdown .digit[data-value="9"] div:nth-child(3),
.countdown .digit[data-value="9"] div:nth-child(4),
.countdown .digit[data-value="9"] div:nth-child(5),
.countdown .digit[data-value="9"] div:nth-child(6),
.countdown .digit[data-value="9"] div:nth-child(7),
.countdown .digit[data-value="9"] div:nth-child(8),
.countdown .digit[data-value="9"] div:nth-child(9){
    top: 50px;
    opacity: 0;
}

.countdown .digit[data-value="9"] div:nth-child(1) {
    top: -50px;
    opacity: 0;
}

.countdown .digit div{
    position: absolute;
}

@media(min-width: 992px) {
    .countdown-container{
        position:fixed;
        bottom: 0;
        right: 10px;
        z-index: 3;
        background-color: white;
        padding: 5px;
    }
    .countdown-container > h2,
    .countdown-container > h4{
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .countdown-container.meal-cancellation-countdown-container > h4,
    .countdown-container.meal-request-countdown-container > h4 {
        text-align: center;
    }
    .countdown-container.meal-request-countdown-container{
        max-width: 300px;
    }
    .countdown{
        background-color: #ffab0d;
        color: white;
        padding: 20px;
        border-radius: 10px;
        justify-content: center;
        transition: background-color ease-in-out 1s;
    }

    .countdown.finished{
        background-color: #b7000f;
    }
}

.field.field-simple.invalid {
    background-color: #f3c8cc;
    color: #b7000f;
}
.calendar-pager {
    font-size: 20px;
    padding: 5px 0;
    margin-bottom: 10px;
    color: #004735;
    text-align: center;
}

.calendar-pager div {
    display: inline-block;
    width: 66.66%;
}

.calendar-pager button:first-child,
.calendar-pager button:last-child {
    width: 16.66%;
    background: none;
    font-size: 20px;
    color: #004735;
    cursor: pointer;
    font-family: 'Oxygen',serif;
}

.calendar-pager button[disabled] {
    visibility: hidden;
}

table.calendar td:first-child {
    border-left: 1px solid #cccccc;
}

table.calendar td {
    position: relative;
    border-right: 1px solid #cccccc;
}

table.calendar th.event::before,
table.calendar td.event::before {
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    border-width: 5px;
    border-style: solid;
    border-color: #ffab0d #ffab0d transparent transparent;
}

table.calendar td.disabled-day {
    background-color: #e9e9e9;
}

table.calendar td.canceled-day div div:last-child:after {
    content: '\2717';
    color: #d60000;
}

table.calendar td.hungry-day div div:last-child:after {
    content: '\2713';
    color: #004735;
}

table.calendar td.mixed-day div div:last-child:after {
    content: 'H';
    color: #ca8400;
}

table.calendar td.sibling-month-day,
table.calendar td.sibling-month-day.canceled-day div div:last-child:after,
table.calendar td.sibling-month-day.hungry-day div div:last-child:after{
    color: #c5c5c5;
}

table.calendar td.close-to-deadline{
    background-color: #ffab0d;
}

table.calendar {
    user-select: none;
}

table.calendar.student th {
    width: calc(100% / 7);
}

table.calendar.student th span.full-name {
    display: none;
}

table.calendar.student td > div {
    height: 70px;
}

table.calendar.student td > div > div:nth-child(2) {
    font-size: 35px;
    text-align: center;
}

table.calendar.class td:first-child {
    background-color: #ffffff;
}

table.calendar.class th + th {
    min-width: 15px;
}

table.calendar.class td + td {
    text-align: center;
}

table.calendar.class th:first-child,
table.calendar.class td:first-child {
    min-width: 200px;
    position: sticky;
    left: 0;
    z-index: 2;
}

table.calendar.class th {
    position: sticky;
    top: 0;
    z-index: 2;
}

table.calendar.class th.canceled-day{
    background-color: #d60000;
}

table.calendar.class th.disabled-day{
    background-color: #909090;
}

table.calendar.class th:first-child {
    z-index: 3;
}

.calendar-container {
    overflow: auto;
    max-height: calc(100vh - 100px);
    min-height: 100px;
    margin-bottom: 20px;
}

.student-select-block > div:first-child select,
.student-select-block > div:first-child input {
    width: 100%;
}

.student-select-block > div:last-child > button {
    display: block;
    width: 75%;
    margin: 10px auto;
}

.entity-select-block > div:first-child select,
.entity-select-block > div:first-child input{
    width: 100%;
}

.entity-select-block > div:last-child > button {
    display: block;
    width: 75%;
    margin: 10px auto;
}

table.workday-overwrite tr:not(.table-new-row) button {
    background-image: url(~@sapkk/app/static/images/minus.svg);
}

table.move-student tbody tr td:first-child,
table.close-class-dining tbody tr td:first-child {
    text-align: center;
}

table.close-class-dining thead tr th:first-child{
    width: 60px;
}

.adatmodositas .block label,
.adatmodositas .block .field {
    width: 100%;
}

.radio-container {
    white-space: nowrap;
}

.radio-label {
    padding: 0 10px 0 5px;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: top;
    line-height: 32px;
    white-space: normal;
    text-align: justify;
}

table.balance tr:not(.table-empty-indicator) td:last-child {
    font-family: Arial,sans-serif;
    text-align: right;
}

table.balance td.overpayment {
    color: #ff8216;
}

table.balance td.debt {
    color: #d40000;
}

table.xmenza-status tr.part-row {
    font-weight: bold;
}

table.xmenza-status th:nth-child(2) {
    width: 20%;
}

table.xmenza-status td:nth-child(2) {
    text-align: center;
}

.xmenza-status.data-expired {
    width: auto;
    padding-right: 40px;
    background: url(../images/warning.svg) center right/contain no-repeat;
    background-size: 20px;
}

.xmenza-status.error,
.xmenza-status .error {
    color: #d40000;
}

.xmenza-status.warning,
.xmenza-status .warning {
    color: #ff8216;
}

.xmenza-status.info,
.xmenza-status .info {
    color: rgb(26, 82, 156);
    font-style: italic;
}

.xmenza-status.ok,
.xmenza-status .ok {
    color: #008000;
}

.non-selectable-row{
    font-style: italic;
    opacity: 0.5;
}

table.menunaptar-tabla.mobil{
    display: table;
}

table.menunaptar-tabla.mobil th{
    text-align: left;
}

table.menunaptar-tabla.mobil .description{
    min-height: 20px;
}

table.menunaptar-tabla {
    user-select: none;
    width: 100%;
    table-layout:fixed;
    display: none;
}
table.menunaptar-tabla th:first-child,
table.menunaptar-tabla td.week-column,
table.menunaptar-tabla th:nth-child(2),
table.menunaptar-tabla td.menu-item-name{
    text-align: center;
    width: 37px;
    font-weight: bolder;
}

table.menunaptar-tabla td.menu-item-name,
table.menunaptar-tabla td.week-column{
    vertical-align: middle;
}

table.menunaptar-tabla tr td:not(.week-column):not(.menu-item-name){
    width: calc(100% / 7 - 14px);
}

table.menunaptar-tabla tr td:not(.week-column):not(.menu-item-name){
    min-height: 50px;
}

table.menunaptar-tabla th span.full-name {
    display: none;
}
table.menunaptar-tabla td{
    vertical-align: top;
    border-left: 2px solid #bbb;
}
table.menunaptar-tabla td:last-child{
    border-right: 2px solid #bbb;
}
table.menunaptar-tabla td > div > .day{
    min-height: 30px;
    display: flex;
    justify-content: space-between;
}
table.menunaptar-tabla td  .helper-icons{
    display: flex;
    justify-content: space-between;
}

table.menunaptar-tabla td  .helper-icons > .info{
    border-radius: 50%;
    border: 2px solid rgb(26, 82, 154);
    width: 15px;
    height: 15px;
    display: inline-block;
    background-image: url('../images/information.svg');
    background-size: 100%;
    margin-left: 5px;
}

table.menunaptar-tabla div.active:after {
    content: '\2713';
    color: #004735;
}

table.menunaptar-tabla td.disabled-day{
    background-color: #c5c5c5;
}
table.menunaptar-tabla td > div > .description{
    white-space: pre-wrap;
    padding-left: 10px;
    min-height: 50px;
    font-size: 11px;
    position: relative;
    text-align: center;
    word-wrap: break-word;
}

.menunaptar-info-popup .description{
    white-space: pre-wrap;
    padding-left: 10px;
    min-height: 50px;
    font-size: 11px;
    position: relative;
    word-wrap: break-word;
}

table.menunaptar-tabla td.clickable:hover{
    background-color: #f7f7f6;
    cursor: pointer;
}

table.menunaptar-tabla td.active{
    background-color: #DFE8E6;
}

table.menunaptar-tabla td.changed{
    font-style: italic;
}

table.menunaptar-tabla tr > td {
    border-top: 2px solid #bbb;
}
table.menunaptar-tabla tr > td {
    border-bottom: unset;
}
table.menunaptar-tabla {
    border-bottom: 2px solid #bbb;
}
table.menunaptar-tabla tr:not(.new-menu-row) > td {
    border-top: 1px dashed #bbb;
}


@media(min-width: 576px) {
    .calendar-container {
        margin-bottom: 25px;
    }

    table.calendar.student th span.full-name {
        display: inline-block;
    }

    table.calendar.student th span.short-name {
        display: none;
    }

    table.menunaptar-tabla th span.full-name {
        display: inline-block;
    }

    table.menunaptar-tabla th span.short-name {
        display: none;
    }

    table.calendar td.event::before {
        border-width: 7px;
    }

    table.calendar.student td > div {
        height: 80px;
    }

    .student-select-block > div:first-child {
        display: inline-block;
        width: calc(100% - 125px);
    }

    .student-select-block > div:last-child {
        display: inline-block;
        width: 125px;
    }

    .student-select-block > div:last-child > button {
        display: inline-block;
        width: 120px;
        line-height: 30px;
        margin: 1px 0 1px 5px;
        vertical-align: top;
    }

    .entity-select-block > div:first-child {
        display: inline-block;
        width: calc(100% - 250px);
    }

    .entity-select-block > div:last-child {
        display: inline-block;
        width: 250px;
    }

    .entity-select-block > div:last-child > button {
        display: inline-block;
        width: 120px;
        line-height: 30px;
        margin: 1px 0 1px 5px;
        vertical-align: top;
    }
}

td.numeric-table-field > div {
    width: 100%;
}

table.meal-request-fields > tbody > tr > td:nth-child(4),
table.meal-request-fields > tbody > tr > td:nth-child(5),
table.meal-request-fields > tbody > tr > td:nth-child(8){
    text-align: center;
}

table.meal-request-fields > thead > tr > th:nth-child(7),
table.meal-request-fields > thead > tr > th:nth-child(7),
table.meal-request-fields > thead > tr > th:nth-child(8),
table.meal-request-fields > thead > tr > th:nth-child(8){
    width: 50px;
}
table.meal-request-fields > tbody > tr > td:nth-child(7) *{
    text-align: right;
}

table.meal-request-template-objects > tbody > tr > td:first-child{
    text-align: center;
}

table > tbody > tr > td > input[type="checkbox"]{
    width: inherit;
}

.react-pdf__Page__canvas{
    margin: 0 auto;
}
.react-pdf__Document > .react-pdf__Page:not(:first-child){
    border-top: 5px dashed #004735;
}

.speech-bubble {
	position: relative;
    border-radius: .4em;
    width: calc(100% - 40px);
    margin: 20px;
    padding: 10px;
    box-sizing: border-box;
}

.speech-bubble.left{
    background: #004735;
    color: white;
}

.speech-bubble.right{
    border: 1px solid #004735;
    background-color: white;
    color: #004735;
}

.speech-bubble .sender{
    font-weight: bold;
}

.speech-bubble .body{
    margin: 5px;
    white-space: pre-line;
    word-wrap: break-word;
}

.speech-bubble .state{
    font-style: italic;
    width: 50%;
    display: inline-block;
}

.speech-bubble .date{
    text-align: right;
    font-style: italic;
    width: 50%;
    display: inline-block;
}

.speech-bubble:after {
	content: '';
	position: absolute;
	top: 50%;
	width: 0;
    height: 0;
	border: 5px solid transparent;
    margin-top: -5px;
}

.speech-bubble.left:after{
    left: 0;
    margin-top: -5px;
    margin-left: -5px;
	border-left: 0;
	border-right-color: #004735;
}

.speech-bubble.right:after{
    right: 0;
    margin-right: -5px;
	border-right: 0;
	border-left-color: #004735;
}

.speech-bubble-container{
    max-height: 70vh;
    overflow-y: auto;
}

.speech-bubble-container .empty-messages{
    text-align: center;
    font-size: larger;
    opacity: 0.5;
    font-style: italic;
    margin: 20px;
}

.not-authenticated{
    font-size: large;
    text-align: right;
    color: red;
}

.student-data-closed{
    font-size: large;
    text-align: right;
}

.missing-function{
    font-size: large;
    text-align: center;
    color: red;
}

div.diff,
input[type="checkbox"].diff:checked::after,
input[type="checkbox"].diff::after{
    background-color: #ffab0d;
    color: black;
}
input[type="checkbox"].diff:not(:checked)::after{
    color: #ffab0d;
}

input[type="checkbox"].diff[disabled]{
    opacity: 0.5;
}

.text-container > button:not(:first-child){
    margin-left: 15px;
}

.canteen-text-style-bold{
    font-weight: bold;
}

.canteen-text-style-16-font-size{
    font-size: 16px;
}

.canteen-text-style-18-font-size{
    font-size: 18px;
}

.canteen-text-style-italic{
    font-style: italic;
}
.canteen-text-bold-text-in-field-column{
    font-weight: bold;
    margin-left: 200px;
}
.page-actions button.page-action-help {
    background-image: url(../images/question_mark2.svg);
    background-color: transparent;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: transparent;
    width: 30px;
    height: 30px;
}

.page-tabs > ul > li > a{
    color: inherit;
}

.page-tabs > ul > li > a:hover{
    text-decoration: none;
}

@keyframes system-message {
    0% {
        opacity: 0.4;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0.4;
    }
}
.page-actions button.page-action-system_message {
    animation-name: system-message;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    background-image: url(../images/envelope.svg);
    background-color: transparent;
    background-size: 100% 100%;
    background-repeat: no-repeat;
    color: transparent;
    width: 30px;
    position: relative;
    margin-right: 20px;
}
.page-actions button.page-action-system_message::after{
    content: attr(data-count);
    color: red;
    font-weight: bold;
    position: absolute;
    display: inline-block;
    bottom: 50%;
    vertical-align: middle;
    margin-left: 10px;
    border-radius: 50%;
    border: 3px solid red;
    width: 20px;
    height: 20px;
    line-height: 20px;
    margin-bottom: 4px;
    animation-name: system-message;
    animation-duration: 2s;
    animation-iteration-count: infinite;
}

.unread-message-count{
    color: red;
    font-weight: bold;
}

table.meal-requests-table > thead > tr> th:first-child,
table.meal-requests-table > tbody > tr> td:first-child{
    padding-right: 30px;
}
.empty-document-downloader{
    margin-top: 80px;
}

.page-actions {
    text-align: right;
}

.search-result.student-data .unseen::after,
.unseen-e-invoice::after{
    content:' *';
    color:red;
}

.search-result.student-data > tbody > tr >td > span div {
    display: inline-block;
}

.search_result-with-unseen-message *[data-data]::after,
a[data-message-count]::after{
    color: red;
    display: inline-block;
    padding-left: 5px;
    font-weight: bold;
}

.search_result-with-unseen-message *[data-data]::after{
    content: ' (' attr(data-data) ')';
}

a[data-message-count]::after{
    content: ' (' attr(data-message-count) ')';
}

.popup{
    overflow-y: auto;
}

table.documents > tbody > tr > td:nth-child(2){
    white-space: nowrap;
}

table.documents > tbody > tr > td:nth-child(3){
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
}

table.dining-table > thead > tr > th:nth-child(1),
table.dining-table > thead > tr > th:nth-child(2),
table.dining-table > thead > tr > th:nth-child(3){
    width: 33%;
}

.input-container.required::after {
    content: '*';
    color: #ff0000;
    padding: 5px;
    width: 10px;
    display:inline-block;
    box-sizing: border-box;
}

.input-container.required + .checkbox-label{
    max-width: calc(100% - 40px);
}
.checkbox-label.field{
    text-align: justify;
}

.maintance-message.messages.warning{
    font-size: 20px;
    color: #b7000f;
    border-color: #ed541d;
    background-color: #fef5f1;
}


table.transfer-order-rule-table > thead > tr > th:first-child{
    width: 200px;
    height: 50px;
    overflow: hidden;
    background: linear-gradient(to right top, #4a907f,#4a907f calc(50% - 1px),#cccccc calc(50% - 0.5px),#cccccc calc(50% + 0.5px),#4a907f calc(50% + 0.5px),#4a907f 100%);
}


table.transfer-order-rule-table > thead > tr > th:first-child > div:first-child{
    position: absolute;
    top: calc(25% - 7px);
    right: 10px;
    text-align: right;
}

table.transfer-order-rule-table > thead > tr > th:first-child > div:last-child{
    bottom: calc(25% - 7px);
    left: 10px;
    position: absolute;
    text-align: left;
}

table.transfer-order-rule-table td:first-child {
    border-left: 1px solid #cccccc;
}

table.transfer-order-rule-table td {
    position: relative;
    border-right: 1px solid #cccccc;
    min-width: 100px;
}

table.transfer-order-rule-table th,
table.transfer-order-rule-table td:first-child{
    user-select: none;
}


table.transfer-order-rule-table td:first-child {
    background-color: #ffffff;
}

table.transfer-order-rule-table th:first-child,
table.transfer-order-rule-table td:first-child {
    min-width: 200px;
    position: sticky;
    left: 0;
    z-index: 2;
}

table.transfer-order-rule-table td:not(:first-child),
table.transfer-order-rule-table td:not(:first-child) input{
    text-align: right;
    font-family: Arial, sans-serif;
}


table.transfer-order-rule-table th:first-child {
    z-index: 3;
}

.transfer-order-rule-table-container {
    overflow: auto;
    max-height: calc(100vh - 100px);
    min-height: 100px;
    margin-bottom: 20px;
}

table.period-table tr > td:nth-child(3),
table.period-table tr > td:nth-child(4),
table.period-table tr > td:nth-child(7){
    min-width: 100px;
}

table.period-table tr > td:nth-child(6){
    min-width: 150px;
}
table.period-table tr > td:nth-child(7),
table.period-table tr > td:nth-child(8){
    text-align: center;
}

.dining-table tr.deleted{
    opacity: 0.5;
    font-style: italic;
}


table.calendar td.another-class::before{
    display: block;
    content: ' ';
    position: absolute;
    top: 0;
    right: 0;
    border-width: 5px;
    border-style: solid;
    border-color: rgb(26, 82, 154) rgb(26, 82, 154) transparent transparent;
}

@media(min-width: 576px) {
    #content .block-sm.side-menu{
        position: sticky;
    }
    .speech-bubble {
        width: calc(50% - 40px);
    }

    .speech-bubble.right{
        right: 0;
        margin-left: calc(50% + 20px);
    }
    table.calendar td.another-class::before{
        border-width: 7px;
    }
}

@media(min-width: 768px) {
    #content .block-md.side-menu{
        position: sticky;
    }

    table.menunaptar-tabla{
        display: table;
    }
    table.menunaptar-tabla.mobil{
        display: none;
    }
}

@media(min-width: 992px) {
    #content .block-lg.side-menu{
        position: sticky;
    }
}

@media(min-width: 1200px) {
    #content .block-xl.side-menu{
        position: sticky;
    }
}

button.download {
    padding: 0;
}

.modify-table button > .page-loader{
    color: #004735;
}

button.loading{
    padding: 0;
}
.address-text{
    font-style: italic;
}

table.sap-diff-table td:nth-child(5){
    text-align: center;
}

table.school-class-table td:nth-child(3),
table.school-class-table td:nth-child(4) {
    text-align: center;
}

table.balance-table > thead > tr > th:not(:last-child){
    width: 18%;
}

table.balance-table > thead > tr > th:last-child{
    width: 10%;
}

table.balance-table > tbody > tr > td:nth-child(5),
table.balance-table > tbody > tr > td:nth-child(4),
table.balance-table > tfoot > tr > td:nth-child(4),
table.balance-table > tfoot > tr > td:nth-child(3){
    text-align: right;
    font-family: Arial,sans-serif;
}

table.balance-table > tbody > tr > td:last-child{
    text-align: center;
}

table.different-rule-class-table > thead > tr > th:nth-child(2){
    width: 100px;
}
table.different-rule-class-table > tbody > tr > td:nth-child(2){
    text-align: center;
}
table > thead > tr > th.header-with-info{
    position: relative;
    padding-right: 24px;
}
.header-info{
    position: absolute;
    right: 0;
    top: 0;
    border-radius: 50%;
    border: 2px solid rgb(26, 82, 154);
    color: rgb(26, 82, 154);
    background: white;
    width: 15px;
    height: 15px;
    display: inline-block;
    text-align: center;
    font-weight: bold;
    transition: all linear 0.2s;
    margin-top: 4px;
    margin-right: 4px;
    background-image: url('../images/information.svg');
    background-size: 100%;
}

.info-bubble{
    position: absolute;
    background-color: #004735;
    color: white;
    z-index: 10000000;
    padding: 20px;
    border-radius: 10px;
    box-sizing: border-box;
    text-align: justify;
    display: block;
    border: 2px solid white;
    width: 420px;
}

.popup-content .messages{
    margin-top:10px;
}

.non-active-years-balance-sum{
    text-align: right;
    font-weight: bold;
    padding: 0;
}
table.recent-years-balance {
    margin-bottom: 20px;
}
table.recent-years-balance > tbody > tr:not(.recent-year-details) > td,
table.balance-table > tbody > tr > td{
    height: 40px;
}

table.recent-years-balance > tbody > tr > td.non-active-years-balance-sum > .field.field-simple {
    width: 100%;
    padding-right: 5px
}

table.recent-years-balance > thead > tr > th:nth-child(3),
table.recent-years-balance > tbody > tr > td:nth-child(3){
    border: none;
    background-color: transparent;
}

table.recent-years-balance > tbody > tr.recent-year-details > td,
table.recent-years-balance > tbody > tr.recent-year-details > td > div{
    padding-left: 0;
    padding-right: 0;
}

table.recent-years-balance > tbody > tr:not(.recent-year-details) > td {
    font-weight: bold;
}
table.recent-years-balance > tbody > tr:not(.recent-year-details) > td:first-child,
table.recent-years-balance > tbody > tr:not(.recent-year-details) > td:nth-child(2){
    width: 40%;
}
table.recent-years-balance > tbody > tr:not(.recent-year-details) > td:nth-child(2),
table.recent-years-balance > tbody > tr:not(.recent-year-details) > td:nth-child(3) {
    text-align: right;
}
table.recent-years-balance > tbody > tr:not(.recent-year-details) > td:nth-child(3) > button {
    width: 120px;
}

table.recent-years-balance.without-border > thead > tr > th,
table.recent-years-balance.without-border > tbody > tr > td {
    border: 0;
}

.page-guide.maintenance{
    white-space: pre;
}

.text-field{
    display: inline-block;
}

.szamlaformatum{
    line-height: 32px;
}

.szamlaforma{
    font-weight: bold;
}

.szamlaformatum input {
    width: 100px;
}

.szamlaformatum hr{
    color: #e5e5e5;
}

.tajekoztato{
    font-style: italic;
}

table.org-sap-codes tr th:nth-child(3),
table.org-sap-codes tr td:nth-child(3){
    width: 250px;
}
table.org-sap-codes tr td:nth-child(3){
    text-align: right;
}

.old-frontend-version-container{
    position: fixed;
    bottom: 0;
    right: 10px;
    z-index: 2;
}
.old-frontend-version-content{
    border: 1px solid #4a907f;
    padding: 20px;
    width: 300px;
    background-color: #DFE8E6;
}

